import Rest from '@/services/Rest';

/**
 * @typedef {WelcomeService}
 */
export default class WelcomeService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/welcome'
}
