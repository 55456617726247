<template>
  <div class="container-fluid px-0 py-2" v-if="!loading">
    <div class="col-12">
      <div class="p-dash-cliente-master">
        <div class="col-12 col-md-12 col-lg-11 mx-auto">
          <div class="row">
            <!-- <div class="col-12">
                        <div class="row">
                            <div class="col-6 px-0 py-2 aos-init" data-aos="fade" data-aos-delay="100">
                                <img src="~@/assets/img/hub_/hub-logo.svg" class="img-fluid" title="Logo" style="max-height:40px">
                            </div>
                            <div class="col-6 px-0 py-2 aos-init" data-aos="fade" data-aos-delay="100"></div>
                        </div>
                    </div> -->
            <div class="col-12 text-center">
              <h3
                class="font-30 fontm-18 font-b-4 mb-2 pt-2 aos-init"
                data-aos="fade"
                data-aos-delay="200"
                style="color: var(--color)"
              >
                Bem vindo a sua nova experiência City
              </h3>
              <div
                class="w-100 pt-2 aos-init"
                data-aos="fade"
                data-aos-delay="300"
                style="border-top: 1px #e9e9e9 solid"
              >
                <h3
                  class="mb-1 font-15 font-b-5 pt-0 mb-2"
                  style="
                    color: #8d8d8d;
                    line-height: 20px;
                    text-transform: uppercase;
                  "
                >
                  O processo de personalização da sua unidade do HUB está
                  dividido em 03 etapas:
                </h3>
              </div>
            </div>
            <div
              class="col-12 col-md-12 col-lg-11 mx-auto text-center px-0 py-3"
            >
              <div class="col-12">
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-3 mx-auto p-0">
                    <div
                      class="p-4 bg-white bder h-100 aos-init"
                      data-aos="fade"
                      data-aos-delay="100"
                      style="border-radius: 12px"
                    >
                      <div
                        class="text-right aos-init"
                        data-aos="fade"
                        data-aos-delay="400"
                        style="
                          border-bottom: 2px var(--color) solid;
                          margin-bottom: 20px;
                          padding-left: 30px;
                        "
                      >
                        <h2
                          class="mb-1 font-18 font-b-5 pt-0 mb-2"
                          style="color: #8d8d8d"
                        >
                          ETAPA <span class="font-25">01</span>
                        </h2>
                      </div>
                      <div v-html="welcome.step_1"></div>
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-6 col-lg-auto mx-auto mb-2 p-0 align-self-center"
                  >
                    <div
                      class="text-center aos-init pt-4 pb-3"
                      data-aos="fade"
                      data-aos-delay="400"
                    >
                      <img
                        src="~@/assets/img/seta-city-dir.svg"
                        height="35"
                        class="aos-init"
                        data-aos="fade"
                        data-aos-delay="300"
                        alt="Qsuíte"
                        style="max-height: 100px; border-radius: 12px"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-3 mx-auto p-0">
                    <div
                      class="p-4 bg-white bder h-100 aos-init"
                      data-aos="fade"
                      data-aos-delay="200"
                      style="border-radius: 12px"
                    >
                      <div
                        class="text-right aos-init"
                        data-aos="fade"
                        data-aos-delay="400"
                        style="
                          border-bottom: 2px var(--color) solid;
                          margin-bottom: 20px;
                          padding-left: 30px;
                        "
                      >
                        <h2
                          class="mb-1 font-18 font-b-5 pt-0 mb-2"
                          style="color: #8d8d8d"
                        >
                          ETAPA <span class="font-25">02</span>
                        </h2>
                      </div>
                      <div v-html="welcome.step_2"></div>
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-6 col-lg-auto mx-auto mb-2 p-0 align-self-center"
                  >
                    <div
                      class="text-center aos-init pt-4 pb-3"
                      data-aos="fade"
                      data-aos-delay="400"
                    >
                      <img
                        src="~@/assets/img/seta-city-dir.svg"
                        height="35"
                        class="aos-init"
                        data-aos="fade"
                        data-aos-delay="300"
                        alt="Qsuíte"
                        style="max-height: 100px; border-radius: 12px"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-3 mx-auto p-0">
                    <div
                      class="p-4 bg-white bder aos-init mb-3"
                      data-aos="fade"
                      data-aos-delay="300"
                      style="border-radius: 12px"
                    >
                      <div
                        class="text-right aos-init"
                        data-aos="fade"
                        data-aos-delay="400"
                        style="
                          border-bottom: 2px var(--color) solid;
                          margin-bottom: 20px;
                          padding-left: 30px;
                        "
                      >
                        <h2
                          class="mb-1 font-18 font-b-5 pt-0 mb-2"
                          style="color: #8d8d8d"
                        >
                          ETAPA <span class="font-25">03</span>
                        </h2>
                      </div>
                      <div v-html="welcome.step_3"></div>
                    </div>
                    <div
                      class="px-4 py-2 mt-3 bder aos-init"
                      data-aos="fade"
                      data-aos-delay="300"
                      style="border-radius: 12px"
                    >
                      <div class="col-12 text-center">
                        <button
                          @click.prevent="initKit"
                          type="submit"
                          class="btn btn-info pulse-mvp py-2"
                          style="background-color: var(--color); width: 200px"
                        >
                          <img
                            src="~@/assets/img/hub_/ico-touch-pad-w.png"
                            height="30"
                            class="mr-2"
                            alt="Qsuíte"
                          />
                          <span style="font-size: 18px">Iniciar</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BaseClientKitConditions />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginFacebookService from "@/services/resources/LoginFacebookService";
import LoginGoogleService from "@/services/resources/LoginGoogleService";
import LoginTwitterService from "@/services/resources/LoginTwitterService";
import ThemeService from "@/services/resources/ThemeService";
import WelcomeService from "@/services/resources/WelcomeService";

const serviceWelcome = WelcomeService.build();
const serviceTheme = ThemeService.build();
const serviceGoogle = LoginGoogleService.build();
const serviceFacebook = LoginFacebookService.build();
const serviceTwitter = LoginTwitterService.build();

export default {
  data() {
    return {
      loading: false,
      email: null,
      password: null,
      sentence: "VIVA A EXPERIÊNCIA CITY VIVA O EXTRAORDINÁRIO",
      component_color: null,
      background_color: "#fff",
      active_register: null,
      desktop_background: "bg-login.jpg",
      mobile_background: null,
      error: false,
      welcome: {
        step_1: null,
        step_2: null,
        step_3: null,
      },
    };
  },
  methods: {
    initKit() {
      if (window.sessionStorage.KitTema == "tema2") {
        this.$router.push({ name: "KitUnityV2" });
      } else {
        this.$router.push({ name: "KitUnity" });
      }
    },
    required(image) {
      if (image.includes("https")) {
        return image;
      }

      return require(`@/assets/img/${image}`);
    },
    login() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            username: this.email,
            password: this.password,
          };

          this.$store
            .dispatch("loginRequest", data)
            .then(() => {})
            .catch((err) => {
              this.error = true;
            });
        }
      });
    },
    loginFacebook() {
      let data = {
        company_id: 1,
        type: "Client",
      };

      serviceFacebook
        .search(data)
        .then((resp) => {
          window.open(resp.url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loginGoogle() {
      let data = {
        company_id: 1,
        type: "Client",
      };
      serviceGoogle
        .search(data)
        .then((resp) => {
          window.open(resp.url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loginTwitter() {
      let data = {
        company_id: 1,
        type: "Client",
      };
      serviceTwitter
        .search(data)
        .then((resp) => {
          window.open(resp.url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchTheme() {
      let data = {
        domain: window.location.hostname,
      };

      serviceTheme.search(data).then((resp) => {
        resp.forEach((element) => {
          switch (element.key) {
            case "PORTAL_CLIENT_SENTENCE":
              this.sentence = element.value;
              break;
            case "PORTAL_CLIENT_COMPONENT_COLOR":
              this.component_color = element.value;
              break;
            case "PORTAL_CLIENT_BACKGROUND_COLOR":
              this.background_color = element.value;
              break;
            case "PORTAL_CLIENT_ACTIVE_REGISTER":
              this.active_register = element.value;
              break;
            case "PORTAL_CLIENT_BACKGROUND_DESKTOP":
              this.desktop_background = element.value;
              break;
            case "PORTAL_CLIENT_BACKGROUND_MOBILE":
              this.mobile_background = element.value;
              break;
            case "PORTAL_CLIENT_ACTIVE_REGISTER":
              this.active_register = element.value;
              break;
          }
        });
      });
    },
    fetchWelcome() {
      this.loading = true;
      serviceWelcome
        .search()
        .then((resp) => {
          this.welcome = resp;
          if (Object.keys(this.welcome).length > 0 && !this.welcome.is_active) {
            this.$router.push({ name: "KitUnity" });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    this.loading = true;
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (sessionStorage.KitTema == "tema2") {
      this.$router.push("/kit/unidade/v2");
    } else {
      this.fetchTheme();
      this.fetchWelcome();
    }
  },
};
</script>